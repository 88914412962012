@charset "UTF-8";
/* ==========================================================
 top
========================================================== */
/* ==========================================================
 * Variables
 *==========================================================*/
/* ==========================================================
 * Mixin
 *==========================================================*/
/* hero-top-01
---------------------------------------------------------- */
.hero-top-01 {
  position: relative;
  overflow: hidden;
}

.hero-top-01 .hero-top-slider {
  position: relative;
  z-index: 1;
}

.hero-top-01 .hero-top-video {
  position: static !important;
}

.hero-top-01 .hero-top-video video {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .hero-top-01 .hero-top-video video {
    display: none;
  }
}

.hero-top-01 .hero-top-video video.vidbacking-active-block-back {
  top: 60%;
}

.hero-top-01 .box-item {
  position: relative;
  color: #fff;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  min-height: 550px;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 768px) {
  .hero-top-01 .box-item.slide1 {
    background-image: url("/inc/image/top/mainimg_01.jpg");
    background-size: cover;
  }
}

@media screen and (max-width: 768px) {
  .hero-top-01 .box-item.slide2 {
    display: block;
    background-image: url("/inc/image/top/mainimg_02.jpg");
    background-size: cover;
  }
}

@media screen and (max-width: 768px) {
  .hero-top-01 .box-item.slide3 {
    display: block;
    background-image: url("/inc/image/top/mainimg_03.jpg");
    background-size: cover;
  }
}

@media screen and (max-width: 768px) {
  .hero-top-01 .box-item.slide4 {
    display: block;
    background-image: url("/inc/image/top/mainimg_04.jpg");
    background-size: cover;
  }
}

@media screen and (max-width: 768px) {
  .hero-top-01 .box-item.slide5 {
    display: block;
    background-image: url("/inc/image/top/mainimg_05.jpg");
    background-size: cover;
  }
}

@media screen and (max-width: 768px) {
  .hero-top-01 .box-item {
    background-size: auto;
    background-attachment: inherit;
    background-position: center center;
    min-height: 300px;
  }
}

.hero-top-01 .box-item .image {
  width: 100%;
}

.hero-top-01 .box-item .image img {
  width: 100%;
}

.hero-top-01 .box-item .box-text-main {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  z-index: 1;
  text-align: center;
}

.hero-top-01 .box-item .box-text-main .ttl-main {
  font-weight: 100;
  font-size: 7.7rem;
  line-height: 1.1;
  font-family: 'Roboto';
}

@media screen and (max-width: 768px) {
  .hero-top-01 .box-item .box-text-main .ttl-main {
    padding: 0 10px;
    font-size: 7.7rem;
  }
}

@media screen and (max-width: 600px) {
  .hero-top-01 .box-item .box-text-main .ttl-main {
    font-size: 3.3rem;
  }
}

.hero-top-01 .box-item .box-text-main .ttl-sub {
  font-size: 18px;
  font-size: 1.8rem;
  font-family: 'Roboto';
}

@media screen and (max-width: 768px) {
  .hero-top-01 .box-item .box-text-main .ttl-sub {
    padding: 0 45px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 600px) {
  .hero-top-01 .box-item .box-text-main .ttl-sub {
    font-size: 1.2rem;
  }
}

.hero-top-01 .box-item .box-text-info {
  position: absolute;
  left: 50%;
  bottom: 150px;
  z-index: 1;
  width: 545px;
  text-align: center;
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .hero-top-01 .box-item .box-text-info {
    display: none;
  }
}

.hero-top-01:hover .slick-prev, .hero-top-01:hover .slick-next {
  display: block !important;
}

.hero-top-01 .slick-prev, .hero-top-01 .slick-next {
  overflow: hidden;
  position: absolute;
  z-index: 1001;
  top: 50%;
  width: 30px;
  height: 58px;
  text-indent: -999px;
  transform: translateY(-50%);
  outline: none;
}

@media screen and (min-width: 769px) {
  .hero-top-01 .slick-prev, .hero-top-01 .slick-next {
    display: none !important;
  }
  .hero-top-01 .slick-prev:hover, .hero-top-01 .slick-next:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 768px) {
  .hero-top-01 .slick-prev, .hero-top-01 .slick-next {
    display: block;
    width: 15px;
    height: 29px;
  }
}

.hero-top-01 .slick-prev {
  left: 15px;
  background: url(/inc/image/top/btn_slide_prev.png) no-repeat;
}

@media screen and (max-width: 768px) {
  .hero-top-01 .slick-prev {
    left: 6px;
    background-size: 15px 29px;
  }
}

.hero-top-01 .slick-next {
  right: 15px;
  background: url(/inc/image/top/btn_slide_next.png) no-repeat;
}

@media screen and (max-width: 768px) {
  .hero-top-01 .slick-next {
    right: 6px;
    background-size: 15px 29px;
  }
}

.hero-top-01 .slick-dots {
  position: absolute;
  bottom: 15px;
  z-index: 1001;
  width: 100%;
  padding: 0 15px;
  text-align: right;
  font-size: 0;
}

@media screen and (max-width: 768px) {
  .hero-top-01 .slick-dots {
    padding: 0;
    text-align: center;
  }
}

.hero-top-01 .slick-dots li {
  display: inline-block;
  padding: 0 3px;
}

.hero-top-01 .slick-dots li button {
  overflow: hidden;
  width: 8px;
  height: 8px;
  border-radius: 6px;
  background: #666;
  text-indent: -999px;
  outline: none;
}

.hero-top-01 .slick-dots li button:hover {
  background: #cccc00;
}

.hero-top-01 .slick-dots li.slick-active button {
  background: #f2f2f278;
}

/* sec-top-01
---------------------------------------------------------- */
.sec-top-01 {
  padding-bottom: 25px;
}

.sec-top-02, .sec-top-03, .sec-top-04 {
  position: relative;
  padding: 30px 0;
}

@media screen and (max-width: 768px) {
  .sec-top-02, .sec-top-03, .sec-top-04 {
    padding-top: 25px;
  }
}

.sec-top-03 {
  padding-bottom: 50px;
  background: #f7f7f7;
}

.sec-top-04 {
  padding-bottom: 0;
}

.sec-top-04 .ttl-cmn-03 {
  margin-bottom: 30px;
}

.sec-top-05 {
  text-align: center;
  padding: 40px 10px 0 10px;
}

@media screen and (max-width: 600px) {
  .sec-top-05 {
    padding-bottom: 40px;
  }
}

.sec-top-05 .ttl-cmn-03 {
  margin-bottom: 0;
}

.sec-top-05 div {
  margin-top: 20px;
  display: inline-block;
}

.sec-top-05 div a:first-of-type {
  margin-right: 15px;
}

.sec-top-05 div img {
  max-width: 100%;
}

/* mod-top-tab-01
---------------------------------------------------------- */
.mod-top-tab-01 {
  position: relative;
  z-index: 2;
  width: 830px;
  margin: -80px auto 30px;
}

@media screen and (max-width: 768px) {
  .mod-top-tab-01 {
    width: 100%;
    margin: -50px auto 15px;
  }
}

.mod-top-tab-01 .list-button {
  margin-left: -10px;
}

.mod-top-tab-01 .list-button:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 768px) {
  .mod-top-tab-01 .list-button {
    margin-left: -4px;
  }
}

.mod-top-tab-01 .list-button li {
  float: left;
  position: relative;
  width: 25%;
  padding-left: 10px;
}

@media screen and (max-width: 768px) {
  .mod-top-tab-01 .list-button li {
    padding-left: 4px;
  }
}

.mod-top-tab-01 .list-button li.is-active:after {
  position: absolute;
  bottom: -31px;
  left: 50%;
  width: 20px;
  height: 16px;
  margin-left: -10px;
  background: url(/inc/image/top/ico_arrow_border.png) no-repeat;
  content: "";
}

@media screen and (max-width: 768px) {
  .mod-top-tab-01 .list-button li.is-active:after {
    bottom: -16px;
    width: 12px;
    height: 11px;
    background: url(/inc/image/top/ico_arrow_border_sp.png) no-repeat;
    background-size: 12px 11px;
  }
}

.mod-top-tab-01 .btn-tab {
  overflow: hidden;
  position: relative;
  display: block;
  height: 145px;
  padding-top: 24px;
  border-radius: 5px;
  background: url(/inc/image/top/bg_top_tab.png) center center repeat-x;
  text-align: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  transition: all 0.2s;
}

@media screen and (max-width: 768px) {
  .mod-top-tab-01 .btn-tab {
    height: 73px;
    padding-top: 9px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.45);
    background-size: auto 73px;
  }
}

.mod-top-tab-01 .btn-tab .title {
  padding-top: 70px;
  color: #fff;
  font-size: 30px;
  font-size: 3rem;
  line-height: .8;
  background-size: 50px 50px;
  background-position: top center;
  background-repeat: no-repeat;
  transition: color 0.2s;
}

@media screen and (max-width: 768px) {
  .mod-top-tab-01 .btn-tab .title {
    height: 73px;
    padding-top: 38px;
    font-size: 15px;
    font-size: 1.5rem;
    background-size: 30px 30px;
  }
}

.mod-top-tab-01 .btn-tab .title span {
  font-size: 23px;
  font-size: 2.3rem;
}

@media screen and (max-width: 768px) {
  .mod-top-tab-01 .btn-tab .title span {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.mod-top-tab-01 .btn-tab .title.icon-01 {
  background-image: url(/inc/image/top/ico_tab_01_no.png);
}

.mod-top-tab-01 .btn-tab .title.icon-02 {
  background-image: url(/inc/image/top/ico_tab_02_no.png);
}

.mod-top-tab-01 .btn-tab .title.icon-03 {
  background-image: url(/inc/image/top/ico_tab_03_no.png);
}

.mod-top-tab-01 .btn-tab .title.icon-04 {
  background-image: url(/inc/image/top/ico_tab_04_no.png);
}

.mod-top-tab-01 .btn-tab.external:after {
  position: absolute;
  top: 15px;
  right: 7px;
  width: 16px;
  height: 14px;
  margin-top: -7px;
  background: url(/inc/image/top/ico_blank_02.png) no-repeat;
  background-size: 16px 14px;
  content: "";
}

.mod-top-tab-01 .btn-tab:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.04);
}

.mod-top-tab-01 .btn-tab:hover .title, .mod-top-tab-01 .btn-tab.is-active .title {
  color: #fcff04;
}

.mod-top-tab-01 .btn-tab:hover .icon-01, .mod-top-tab-01 .btn-tab.is-active .icon-01 {
  background-image: url(/inc/image/top/ico_tab_01_on.png);
}

.mod-top-tab-01 .btn-tab:hover .icon-02, .mod-top-tab-01 .btn-tab.is-active .icon-02 {
  background-image: url(/inc/image/top/ico_tab_02_on.png);
}

.mod-top-tab-01 .btn-tab:hover .icon-03, .mod-top-tab-01 .btn-tab.is-active .icon-03 {
  background-image: url(/inc/image/top/ico_tab_03_on.png);
}

.mod-top-tab-01 .btn-tab:hover .icon-04, .mod-top-tab-01 .btn-tab.is-active .icon-04 {
  background-image: url(/inc/image/top/ico_tab_04_on.png);
}

.mod-top-tab-01 .btn-tab.is-active:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background-color: #fcff04;
  content: "";
}

/* box-top-news
---------------------------------------------------------- */
.box-top-news {
  position: relative;
}

@media screen and (max-width: 768px) {
  .box-top-news {
    padding-bottom: 20px;
  }
}

.box-top-news .btn-news-top {
  position: absolute;
  white-space: nowrap;
  top: 5px;
  right: 0;
  width: auto;
  display: inline-block;
  background-color: #FFF;
}

.box-top-news .btn-news-top span {
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .box-top-news .btn-news-top {
    display: none;
  }
}

.box-top-news .box-news-highlight {
  margin-bottom: 20px;
}

/* box-tab-content
---------------------------------------------------------- */
.box-tab-content {
  position: relative;
  padding: 45px 0 30px;
  border-top: 1px solid #c0bebe;
}

@media screen and (max-width: 768px) {
  .box-tab-content {
    padding: 20px 0 15px;
  }
}

.box-tab-content:before, .box-tab-content:after {
  position: absolute;
  top: -1px;
  left: 0;
  width: 8px;
  height: 20px;
  background: url(/inc/image/top/bg_line_corner_01.png) no-repeat;
  content: "";
}

@media screen and (max-width: 768px) {
  .box-tab-content:before, .box-tab-content:after {
    width: 4px;
    height: 10px;
    background-size: 4px 10px !important;
  }
}

.box-tab-content:after {
  left: auto;
  right: 0;
  background: url(/inc/image/top/bg_line_corner_02.png) no-repeat;
}

.box-tab-content .box-item .card-cmn-01 {
  margin-bottom: 10px;
}

/* list-top-news-01
---------------------------------------------------------- */
.list-top-news-01 li {
  border-bottom: 1px solid #e0e0e0;
}

.list-top-news-01 li:last-child {
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .list-top-news-01 li {
    margin: 0 -10px;
    padding: 0 10px;
  }
}

.list-top-news-01 .box-news-item {
  display: table;
  width: 100%;
  padding: 8px 10px;
  transition: background-color 0.2s;
}

@media screen and (max-width: 768px) {
  .list-top-news-01 .box-news-item {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0;
  }
}

@media screen and (min-width: 769px) {
  .list-top-news-01 .box-news-item:hover {
    background: #f0f0f0;
  }
}

.list-top-news-01 .box-news-item .box-tag {
  display: table-cell;
  width: 112px;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .list-top-news-01 .box-news-item .box-tag {
    display: block;
    width: 50%;
    order: 1;
  }
}

.list-top-news-01 .box-news-item .txt-date {
  display: table-cell;
  width: 150px;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .list-top-news-01 .box-news-item .txt-date {
    display: block;
    width: 50%;
    padding-top: 3px;
    text-align: right;
    order: 2;
  }
}

.list-top-news-01 .box-news-item .txt-info {
  display: table-cell;
  padding: 0 15px;
  vertical-align: top;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .list-top-news-01 .box-news-item .txt-info {
    display: block;
    width: 100%;
    padding: 8px 0 0;
    order: 3;
  }
}

/* list-top-news-02
---------------------------------------------------------- */
.list-top-news-02 li {
  border-bottom: 1px solid #e0e0e0;
}

.list-top-news-02 .item-news {
  padding: 8px 10px;
}

@media screen and (max-width: 768px) {
  .list-top-news-02 .item-news {
    padding: 8px 5px 6px;
  }
}

.list-top-news-02 .item-news .box-head {
  width: 100%;
  margin-bottom: 4px;
}

@media screen and (max-width: 768px) {
  .list-top-news-02 .item-news .box-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
}

.list-top-news-02 .item-news .box-head .txt-date {
  display: inline-block;
  line-height: 1.2;
}

@media screen and (max-width: 768px) {
  .list-top-news-02 .item-news .box-head .txt-date {
    order: 2;
    display: block;
    padding-top: 5px;
  }
}

.list-top-news-02 .item-news .box-head .box-tag {
  display: inline-block;
  padding-left: 10px;
}

@media screen and (max-width: 768px) {
  .list-top-news-02 .item-news .box-head .box-tag {
    order: 1;
    display: block;
    padding-left: 0;
  }
}

@media screen and (min-width: 769px) {
  .list-top-news-02 .item-news:hover .txt-info {
    color: #003399;
    text-decoration: underline;
  }
}

/* list-top-recruit-link
---------------------------------------------------------- */
.list-top-recruit-link li {
  overflow: hidden;
  border: 1px solid #e0e0e0;
  border-bottom: none;
}

.list-top-recruit-link li:first-child {
  border-bottom: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.list-top-recruit-link li:last-child {
  border-bottom: 1px solid #e0e0e0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.list-top-recruit-link li a {
  position: relative;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  padding: 10px 30px 10px 15px;
  color: #000;
  text-align: left;
  font-weight: 500;
}

.list-top-recruit-link li a:after {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 16px;
  height: 14px;
  margin-top: -7px;
  background: url(/inc/image/top/ico_blank_01.png) 0 0 no-repeat;
  background-size: 16px 14px;
  content: "";
}

@media screen and (min-width: 769px) {
  .list-top-recruit-link li a:hover:after {
    background: url(/inc/image/top/ico_blank_02.png) 0 0 no-repeat;
    background-size: 16px 14px;
  }
}

/* list-top-banner
---------------------------------------------------------- */
.list-top-banner {
  overflow: hidden;
  margin: -10px 0 0 -10px;
}

@media screen and (max-width: 768px) {
  .list-top-banner {
    margin: -20px 0 0 0;
  }
}

.list-top-banner li {
  float: left;
  width: 50%;
  margin-top: 20px;
  padding-left: 10px;
}

@media screen and (max-width: 768px) {
  .list-top-banner li {
    float: none;
    width: 100%;
    padding-left: 0;
  }
}

@media screen and (min-width: 769px) {
  .list-top-banner li:hover .box-image img {
    transform: scale(1.04);
  }
}

.list-top-banner .box-image {
  overflow: hidden;
}

.list-top-banner .box-image img {
  display: block;
  width: 100%;
  transition: transform 0.2s ease-in-out;
}

.list-top-banner .text {
  margin-top: 16px;
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .list-top-banner .text {
    margin-top: 8px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

/* box-top-article-01
---------------------------------------------------------- */
.box-top-article-01 {
  overflow: hidden;
}

.box-top-article-01 .box-side-01 {
  float: right;
  width: 400px;
  padding-left: 20px;
}

@media screen and (max-width: 768px) {
  .box-top-article-01 .box-side-01 {
    float: none;
    width: auto;
    margin-bottom: 22px;
    padding-left: 0;
  }
}

.box-top-article-01 .box-side-02 {
  float: left;
  width: 600px;
}

@media screen and (max-width: 768px) {
  .box-top-article-01 .box-side-02 {
    float: none;
    width: auto;
  }
}

.box-top-article-01 .title-main {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .box-top-article-01 .title-main {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

/* box-top-shop-01
---------------------------------------------------------- */
.box-top-shop-01 {
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .box-top-shop-01 {
    margin-bottom: 15px;
  }
}

/* box-top-slider-01
---------------------------------------------------------- */
.box-top-slider-01 {
  position: relative;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .box-top-slider-01 {
    margin-bottom: 15px;
  }
}

.box-top-slider-01 .item-slider a {
  outline: none;
}

@media screen and (min-width: 769px) {
  .box-top-slider-01 .item-slider a img {
    transition: opacity 0.2s ease;
  }
  .box-top-slider-01 .item-slider a:hover img {
    opacity: 0.7;
  }
}

.box-top-slider-01 .item-slider img {
  width: 100%;
}

.box-top-slider-01 .slick-dots {
  text-align: center;
  padding-top: 9px;
  font-size: 0;
}

@media screen and (max-width: 768px) {
  .box-top-slider-01 .slick-dots {
    padding-top: 5px;
  }
}

.box-top-slider-01 .slick-dots li {
  display: inline-block;
  padding: 0 3px;
}

.box-top-slider-01 .slick-dots li button {
  overflow: hidden;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #d2d2d2;
  text-indent: -999px;
}

@media screen and (max-width: 768px) {
  .box-top-slider-01 .slick-dots li button {
    width: 6px;
    height: 6px;
  }
}

.box-top-slider-01 .slick-dots li.slick-active button {
  background-color: #003399;
}

/* box-top-btnlink-01
---------------------------------------------------------- */
.box-top-btnlink-01 .list-link li {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .box-top-btnlink-01 .list-link li {
    margin-bottom: 5px;
  }
}

.box-top-btnlink-01 .list-link li:last-child {
  margin-bottom: 0;
}

.box-top-btnlink-01 .box-link-01 {
  overflow: hidden;
  position: relative;
  display: table;
  width: 100%;
  height: 60px;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  transition: border-color 0.25s, box-shadow 0.25s;
}

.box-top-btnlink-01 .box-link-01:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.box-top-btnlink-01 .box-link-01:hover .title {
  color: #003399;
}

@media screen and (max-width: 768px) {
  .box-top-btnlink-01 .box-link-01 {
    height: 40px;
    margin-bottom: 5px;
  }
}

.box-top-btnlink-01 .box-link-01.ico-blank:after {
  position: absolute;
  top: 50%;
  right: 7px;
  width: 16px;
  height: 14px;
  margin-top: -7px;
  background: url(/inc/image/top/ico_blank_01.png) no-repeat;
  background-size: 16px 14px;
  content: "";
}

.box-top-btnlink-01 .box-link-01 .icon {
  display: table-cell;
  width: 54px;
  border-right: 1px solid #cdcdcd;
  transition: background-color 0.2s;
}

@media screen and (max-width: 768px) {
  .box-top-btnlink-01 .box-link-01 .icon {
    width: 34px;
  }
}

.box-top-btnlink-01 .box-link-01 .icon.ico-01 {
  background: url(/inc/image/top/ico_top_link_01_no.png) center center no-repeat;
}

@media screen and (max-width: 768px) {
  .box-top-btnlink-01 .box-link-01 .icon.ico-01 {
    background: url(/inc/image/top/ico_top_link_01_sp.png) center center no-repeat;
    background-size: 19px 20px;
  }
}

.box-top-btnlink-01 .box-link-01 .icon.ico-02 {
  background: url(/inc/image/top/ico_top_link_02_no.png) center center no-repeat;
}

@media screen and (max-width: 768px) {
  .box-top-btnlink-01 .box-link-01 .icon.ico-02 {
    background: url(/inc/image/top/ico_top_link_02_sp.png) center center no-repeat;
    background-size: 19px 20px;
  }
}

.box-top-btnlink-01 .box-link-01 .icon.ico-03 {
  background: url(/inc/image/top/ico_top_link_03_no.png) center center no-repeat;
}

@media screen and (max-width: 768px) {
  .box-top-btnlink-01 .box-link-01 .icon.ico-03 {
    background: url(/inc/image/top/ico_top_link_03_sp.png) center center no-repeat;
    background-size: 19px 20px;
  }
}

.box-top-btnlink-01 .box-link-01 .icon.ico-04 {
  background: url(/inc/image/top/ico_top_link_04_no.png) center center no-repeat;
  background-size: 14px 26px;
}

.box-top-btnlink-01 .box-link-01 .icon.ico-05 {
  background: url(/inc/image/top/ico_top_link_05_no.png) center center no-repeat;
  background-size: 22px 22px;
}

.box-top-btnlink-01 .box-link-01 .icon.ico-06 {
  background: url(/inc/image/top/ico_top_link_06_no.png) center center no-repeat;
  background-size: 26px 21px;
}

@media screen and (min-width: 769px) {
  .box-top-btnlink-01 .box-link-01:hover .icon {
    background-color: #003399 !important;
  }
  .box-top-btnlink-01 .box-link-01:hover .icon.ico-01 {
    background: url(/inc/image/top/ico_top_link_01_on.png) center center no-repeat;
  }
  .box-top-btnlink-01 .box-link-01:hover .icon.ico-02 {
    background: url(/inc/image/top/ico_top_link_02_on.png) center center no-repeat;
  }
  .box-top-btnlink-01 .box-link-01:hover .icon.ico-03 {
    background: url(/inc/image/top/ico_top_link_03_on.png) center center no-repeat;
  }
  .box-top-btnlink-01 .box-link-01:hover .icon.ico-04 {
    background: url(/inc/image/top/ico_top_link_04_on.png) center center no-repeat;
    background-size: 14px 26px;
  }
  .box-top-btnlink-01 .box-link-01:hover .icon.ico-05 {
    background: url(/inc/image/top/ico_top_link_05_on.png) center center no-repeat;
    background-size: 22px 22px;
  }
  .box-top-btnlink-01 .box-link-01:hover .icon.ico-06 {
    background: url(/inc/image/top/ico_top_link_06_on.png) center center no-repeat;
    background-size: 26px 21px;
  }
}

.box-top-btnlink-01 .box-link-01 .info {
  display: table-cell;
  padding: 5px 30px 5px 14px;
  line-height: 1.4;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .box-top-btnlink-01 .box-link-01 .info {
    padding: 5px 25px 5px 9px;
  }
}

.box-top-btnlink-01 .box-link-01 .info .title {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .box-top-btnlink-01 .box-link-01 .info .title {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.box-top-btnlink-01 .box-link-01 .info .text {
  color: #393939;
  font-size: 11px;
  font-size: 1.1rem;
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .box-top-btnlink-01 .box-link-01 .info .text {
    font-size: 9px;
    font-size: 0.9rem;
  }
}

.box-top-btnlink-01 .box-link-02 {
  overflow: hidden;
  position: relative;
  display: table;
  width: 100%;
  height: 60px;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  transition: box-shadow 0.25s;
}

.box-top-btnlink-01 .box-link-02:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .box-top-btnlink-01 .box-link-02 {
    height: 40px;
    margin-bottom: 5px;
  }
}

.box-top-btnlink-01 .box-link-02.ico-blank:after {
  position: absolute;
  top: 50%;
  right: 7px;
  width: 16px;
  height: 14px;
  margin-top: -7px;
  background: url(/inc/image/top/ico_blank_03.png) no-repeat;
  background-size: 16px 14px;
  content: "";
}

.box-top-btnlink-01 .box-link-02 .icon {
  overflow: hidden;
  display: table-cell;
  width: 66px;
}

@media screen and (max-width: 768px) {
  .box-top-btnlink-01 .box-link-02 .icon {
    width: 33px;
  }
}

.box-top-btnlink-01 .box-link-02 .icon.ico-01 {
  background: url(/inc/image/top/img_top_button_01.jpg) center center no-repeat;
  background-size: cover;
}

.box-top-btnlink-01 .box-link-02 .icon.ico-02 {
  background: url(/inc/image/top/img_top_button_02.jpg) center center no-repeat;
  background-size: cover;
}

.box-top-btnlink-01 .box-link-02 .info {
  display: table-cell;
  padding: 5px 13px;
  text-align: left;
  vertical-align: middle;
}

/* btn-top-article-01
---------------------------------------------------------- */
.btn-top-article-01 {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .btn-top-article-01 {
    margin-top: 5px;
  }
}

/* box-top-service-01
---------------------------------------------------------- */
.box-top-service-01 .box-service-search {
  display: table;
  width: 99.999%;
  padding: 12px;
  border: 1px solid #cdcdcd;
  background-color: #f1f1f1;
}

@media screen and (max-width: 768px) {
  .box-top-service-01 .box-service-search {
    display: block;
    width: 100%;
  }
}

.box-top-service-01 .box-service-search .box-title {
  display: table-cell;
  width: 130px;
  padding-left: 5px;
  vertical-align: middle;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .box-top-service-01 .box-service-search .box-title {
    display: block;
    width: auto;
    padding-left: 0;
  }
}

.box-top-service-01 .box-service-search .box-title .ico-search {
  display: inline-block;
  padding-left: 25px;
  color: #003399;
  background: url(/inc/image/top/ico_top_search.png) left center no-repeat;
  background-size: 19px 19px;
  font-weight: 700;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .box-top-service-01 .box-service-search .box-title .ico-search {
    display: block;
    width: auto;
    margin-bottom: 5px;
    padding-left: 16px;
    font-size: 14px;
    font-size: 1.4rem;
    background-size: 13px 13px;
  }
}

.box-top-service-01 .box-service-search .box-search {
  overflow: hidden;
  display: table-cell;
  padding: 4px;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 3px;
  transition: all 0.25s;
}

@media screen and (max-width: 768px) {
  .box-top-service-01 .box-service-search .box-search {
    display: flex;
    padding: 2px;
  }
}

.box-top-service-01 .box-service-search .box-search:active, .box-top-service-01 .box-service-search .box-search:hover {
  border-color: #f8d400;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.box-top-service-01 .box-service-search .box-search .input-search {
  float: left;
  padding: 4px 8px;
  width: 355px;
  background: none;
  border: none;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .box-top-service-01 .box-service-search .box-search .input-search {
    float: none;
    flex: 1;
    width: auto;
    padding: 2px 4px;
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.box-top-service-01 .box-service-search .box-search .input-search::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #878787;
  opacity: 1;
}

.box-top-service-01 .box-service-search .box-search .input-search::-moz-placeholder {
  /* Firefox 19+ */
  color: #878787;
  opacity: 1;
}

.box-top-service-01 .box-service-search .box-search .input-search:-ms-input-placeholder {
  /* IE 10+ */
  color: #878787;
  opacity: 1;
}

.box-top-service-01 .box-service-search .box-search .input-search:-moz-placeholder {
  /* Firefox 18- */
  color: #878787;
  opacity: 1;
}

.box-top-service-01 .box-service-search .box-search .input-search.placeholdersjs {
  /* Firefox 18- */
  color: #878787;
  opacity: 1;
}

.box-top-service-01 .box-service-search .box-search .btn-search {
  float: right;
  width: 76px;
  height: 33px;
  background-color: #003399;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  font-size: 1.5rem;
  -webkit-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.box-top-service-01 .box-service-search .box-search .btn-search:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .box-top-service-01 .box-service-search .box-search .btn-search:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .box-top-service-01 .box-service-search .box-search .btn-search {
    float: none;
    flex: 0 0 60px;
    width: 60px;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

/* list-top-service-01
---------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .list-top-service-01 {
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid #cdcdcd;
  }
}

.list-top-service-01 li {
  float: left;
  width: 33.333%;
  border-right: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
}

@media screen and (min-width: 769px) {
  .list-top-service-01 li:nth-child(3n+1) {
    clear: left;
    border-left: 1px solid #cdcdcd;
  }
}

@media screen and (max-width: 768px) {
  .list-top-service-01 li {
    float: none;
    width: 50%;
  }
}

/* box-top-service-text
---------------------------------------------------------- */
.box-top-service-text {
  margin-top: 22px;
}

@media screen and (max-width: 768px) {
  .box-top-service-text {
    margin-top: 15px;
  }
}

.box-top-service-text .list-service {
  overflow: hidden;
  margin-top: -2px;
  padding-left: 15px;
}

@media screen and (max-width: 768px) {
  .box-top-service-text .list-service {
    padding-left: 0;
  }
}

.box-top-service-text .list-service li {
  float: left;
  position: relative;
  width: 185px;
  margin-top: 2px;
  padding: 0 15px 0 14px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .box-top-service-text .list-service li {
    width: 50%;
    padding: 0 10px 0 12px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 769px) {
  .box-top-service-text .list-service li:nth-child(3n+1) {
    width: 170px;
  }
}

.box-top-service-text .list-service li:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "・";
}

/* box-recruit-visual
---------------------------------------------------------- */
.box-recruit-visual {
  position: relative;
  margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
  .box-recruit-visual {
    margin-bottom: 15px;
  }
}

.box-recruit-visual .image img {
  width: 100%;
}

.box-recruit-visual .box-button {
  position: absolute;
  left: 22px;
  bottom: 38px;
}

@media screen and (max-width: 768px) {
  .box-recruit-visual .box-button {
    left: 10px;
    right: 10px;
    bottom: 10px;
  }
}

.box-recruit-visual .btn-link {
  display: block;
  width: 280px;
  padding: 6px 30px;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 0;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .box-recruit-visual .btn-link {
    width: 100%;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.box-recruit-visual .btn-link:before {
  background-color: #fff;
}

.box-recruit-visual .btn-link:after {
  right: 6px;
  width: 16px;
  height: 14px;
  margin-top: -8px;
  background: url(/inc/image/top/ico_blank_02.png) no-repeat;
  background-size: 16px 14px;
}

@media screen and (min-width: 769px) {
  .box-recruit-visual .btn-link:hover {
    color: #393939;
  }
  .box-recruit-visual .btn-link:hover:after {
    background: url(/inc/image/top/ico_blank_01.png) no-repeat;
    background-size: 16px 14px;
  }
}

/* box-recruit-article-01
---------------------------------------------------------- */
.box-recruit-article-01 {
  overflow: hidden;
}

.box-recruit-article-01 .box-subside-01 {
  float: left;
  width: 290px;
}

@media screen and (max-width: 768px) {
  .box-recruit-article-01 .box-subside-01 {
    float: none;
    width: auto;
    margin-bottom: 15px;
  }
}

.box-recruit-article-01 .box-subside-02 {
  float: left;
  width: 310px;
  padding-left: 18px;
}

@media screen and (max-width: 768px) {
  .box-recruit-article-01 .box-subside-02 {
    float: none;
    width: auto;
    padding-left: 0;
  }
}

/* box-mynavi-link
---------------------------------------------------------- */
.box-mynavi-link {
  overflow: hidden;
  position: relative;
  margin-bottom: 12px;
  border: 1px solid #bfbfbf;
}

.box-mynavi-link a img {
  width: 100%;
  transition: transform 0.2s ease-in-out;
}

.box-mynavi-link a:hover img {
  transform: scale(1.04);
}

/* card-top-service-01
---------------------------------------------------------- */
.card-top-service-01, .card-top-service-02 {
  position: relative;
  transition: box-shadow 0.25s;
}

.card-top-service-01:hover, .card-top-service-02:hover {
  position: relative;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.card-top-service-01 .image, .card-top-service-02 .image {
  overflow: hidden;
  width: 100%;
  height: 175px;
}

.card-top-service-01 .image img, .card-top-service-02 .image img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .card-top-service-01 .image, .card-top-service-02 .image {
    height: auto;
  }
  .card-top-service-01 .image img, .card-top-service-02 .image img {
    width: 100%;
    height: auto;
  }
}

.card-top-service-01 .title, .card-top-service-02 .title {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .card-top-service-01 .title, .card-top-service-02 .title {
    top: 5px;
    left: 6px;
    right: 6px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

/* card-top-service-02
---------------------------------------------------------- */
.card-top-service-02 .image {
  height: 185px;
}

@media screen and (max-width: 768px) {
  .card-top-service-02 .image {
    height: auto;
  }
}
