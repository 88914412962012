/* ==========================================================
 * Variables
 *==========================================================*/

$baseFontColor: #444;

$colorWhite: #ffffff;
$colorBlue01: #003399;
$colorYellow01: #fff000;

$AnchorHoverTextColor: darken($colorBlue01,6);

$headerHeight: 80px;
$headerFixedHeight: 55px;
$headerFixedAnimationSpeed: .2s;
$headerLineHeight: 6px;

$hoverAnimationSpeed: .25s;
$hoverAnimationShadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
$imgAnimationAfterScale: 1.04;

$inputActiveColor: #f8d400;

$defaultFontFamily: 'Noto Sans', "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;

/* ==========================================================
 * Mixin
 *==========================================================*/
@mixin hoverOpacity {
  -webkit-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  &:hover {
    opacity: 0.7;
    @media screen and (max-width: 768px) {
      opacity: 1;
    }
  }
}

@mixin isAnimate {
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin clearFix {
  &:before,
  &:after{
    content: " ";
    display: table;
  }

  &:after{
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }
  &{
    *zoom: 1;
  }
}
